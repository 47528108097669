<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="sizes"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Размеры</v-toolbar-title>
                    <v-spacer />
                    <v-btn color="primary" @click="create">
                        <v-icon>mdi-plus</v-icon> Добавить
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
                {{ item.gender.title }}
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
                <span :class="`true-false-label ${getBadge(item.enabled)}`">
                    &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
                </span>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
                <v-icon color="primary" @click="edit(item)">mdi-pencil</v-icon>
            </template>
        </v-data-table>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Размер
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="editEntity.gender_id"
                                    label="Пол"
                                    :items="genders"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editEntity.code"
                                    outlined
                                    label="Код"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editEntity.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editEntity.chest"
                                    outlined
                                    label="Грудь"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editEntity.waist"
                                    outlined
                                    label="Талия"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editEntity.hips"
                                    outlined
                                    label="Бёдра"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="editEntity.height"
                                    outlined
                                    label="Рост"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                    v-model="editEntity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="editEntity.position"
                                    outlined
                                    label="Сортировка"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editEntity.id"
                        color="error"
                        class="ml-3"
                        @click="remove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'Sizes',
        components: {
        },
        data: () => ({
            editModal: false,
            editEntity: {},
            headers: [
                { text: 'Пол', value: 'gender' },
                { text: 'Сортировка', value: 'position' },
                { text: 'Код', value: 'code' },
                { text: 'Заголовок', value: 'title' },
                { text: 'Грудь', value: 'chest' },
                { text: 'Талия', value: 'waist' },
                { text: 'Бёдра', value: 'hips' },
                { text: 'Рост', value: 'height' },
                { text: 'Активен', value: 'enabled' },
                { text: '', align: 'right', value: 'edit' },
            ],
        }),
        computed: {
            ...mapState('genders', {
                genders: state => state.entities
            }),
            ...mapState('sizes', {
                sizes: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('genders/fetch');
            await store.dispatch('sizes/fetch');
        },
        methods: {
            create() {
                this.editEntity = {
                    code: '',
                    title: '',
                    gender_id: null,
                    chest: '',
                    waist: '',
                    hips: '',
                    position: 0,
                    enabled: true
                };
                this.editModal = true;
            },
            edit(item) {
                this.editEntity = JSON.parse(JSON.stringify(item));
                this.editModal = true;
            },
            async save() {
                try {
                    store.commit('sizes/SET_ENTITY', this.editEntity);
                    await store.dispatch('sizes/save', {entity_id: this.$route.params.id});
                    await store.dispatch('sizes/fetch');
                    this.editModal = false;
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                try {
                    await store.dispatch('sizes/delete', {entity_id: this.$route.params.id, id: this.editEntity.id});
                    await store.dispatch('sizes/fetch');
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
        }
    }
</script>